import { defineStore } from 'pinia';
import {
  fetchCourse,
  fetchExercises,
  fetchCourses,
  updateLesson,
  deleteFile,
  listCourseFiles,
  addLink,
} from '/@/app/services/courses';
import { ISkill, IFile } from '/@/app/types/interfaces';

interface Course {
  id: string;
  caption: string;
  total_lessons: number;
  theme: string;
  lessons: any[];
  outline: any[];
  units: any[];
  disciplines: any[];
  gates: any[];
  grades: any[];
  loading: boolean;
  files: IFile[];
  assessments: any[];
}

export const useCourseStore = defineStore({
  id: 'course',
  state: () => ({
    courses: [] as Course[],
    course: {} as Course,
    skill: {} as ISkill,
    instructors: [] as any[],
    lessons: {} as { [lesson: string]: any },
    questions: [] as any[],
    videos: [] as any[],
    exercises: [] as any[],
    loading: false,
    files: [] as IFile[],
  }),
  getters: {
    lessonsOptions: (state: any) =>
      state.course.lessons
        ? state.course.lessons.map(({ lesson, id, title }) => ({
            title,
            value: id,
            number: lesson,
          }))
        : [],
  },
  actions: {
    fetchCourses(): Promise<void> {
      return new Promise(resolve => {
        const { onSuccess } = fetchCourses();
        onSuccess(({ data }) => {
          this.courses = data;
          resolve();
        });
      });
    },
    fetchExercises(course, lesson) {
      this.loading = true;
      const { onSuccess, onError } = fetchExercises(course, lesson);
      onSuccess(({ data }) => {
        this.exercises = data;
        this.loading = false;
      });
      onError(() => {
        this.loading = false;
      });
    },
    fetchCourse(id) {
      const { onSuccess } = fetchCourse(id);
      onSuccess(({ data }) => {
        this.course = data;
      });
      return { onSuccess };
    },
    fetchCourseFiles(course) {
      this.loading = true;
      const { onSuccess, onError } = listCourseFiles(course);
      onSuccess(({ data }) => {
        this.files = data;
        this.loading = false;
      });
      onError(() => {
        this.loading = false;
      });
    },

    updateLesson(lesson) {
      return new Promise(resolve => {
        if (lesson.id) {
          let { onSuccess } = updateLesson(lesson.course, lesson.id, lesson);
          onSuccess(data => {
            resolve(data);
          });
        }
        resolve({});
      });
    },

    removeKey(file, key) {
      const { [key]: _, ...rest } = file;
      return rest;
    },

    updateFileInStore(file: IFile, keyToUnsave?: string) {
      const index = this.files.findIndex(f => f.id === file.id);
      const updatedData = keyToUnsave
        ? this.removeKey(file, keyToUnsave)
        : file;
      console.log('updatedData', updatedData, 'keyToUnsave', keyToUnsave);
      const { onSuccess } = addLink(
        updatedData,
        file.metadata.course,
        file.metadata.owner,
      );
      onSuccess(() => {
        delete file['new'];
        this.files[index] = { ...file };
      });
    },
    deleteFile(fileId: string) {
      this.loading = true;
      const { onSuccess, onError } = deleteFile(fileId);
      onSuccess(() => {
        this.removeFileFromStore(fileId);
        this.loading = false;
      });

      onError(() => {
        this.loading = false;
      });
    },
    removeFileFromStore(fileId: string) {
      this.files = this.files.filter(file => file.id !== fileId);
    },
    removeAllFilesFromStore() {
      this.files = [];
    },
    addFileToStore(file: IFile, addTo = 'top') {
      if (addTo === 'top') this.files = [file, ...this.files];
      else this.files = [...this.files, file];
    },
  },
});
